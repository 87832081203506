
import { defineComponent } from "vue";
import VSection from "@/components/VSection/index.vue";
import VImage from "@/components/Image.vue";

export default defineComponent({
  name: "TheTechniques",

  components: {
    VImage,
    VSection
  }
});
