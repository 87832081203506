
import { defineComponent } from "vue";

export default defineComponent({
  name: "TheContrast",

  props: {
    content: String,

    highlight: String,

    comment: String,
  }
});
