
import { enUS, zhCN } from "@/plugins/i18n";
import { defineComponent } from "vue";
import Hero from "@/components/Hero/index.vue";
import VideoButton from "@/components/VideoButton/index.vue";
import TheDescription from "./TheDescription.vue";

export default defineComponent({
  name: "TheDrumpTypeHoister",

  components: {
    TheDescription,
    VideoButton,
  },

  computed: {
    title: vm => vm.$t("title"),
    subTitle: vm => vm.$t("subtitle"),
    features: vm => [
      [
        vm.$t("feature1"),
        vm.$t("feature2")
      ],
      [
        vm.$t("feature3"),
        vm.$t("feature4")
      ]
    ]
  },

  i18n: {
    messages: {
      [zhCN]: {
        title: "标准托盘搬运型",
        subtitle: "本设备采用独立变频控制技术，使运输途中的加速和减速非常流畅，不需担心货物散落、倾倒的问题。同时，由于产品框架设计为立式塔形，具有较强的抗震能力，在新老建筑均可安装。",
        feature1: "该机器是作为非载人电梯类以外机械设备得以认定的，每年4次自主点检即可，从而削减了运行成本。",
        feature2: "采用独立变频控制，加速和减速非常流畅，不必担心货物散落、倾倒问题。",
        feature3: "产品采用立式塔型框架，具有较强抗震能力，设备在新老建筑内均可设置。",
        feature4: "采用了完善的安全装置，体现了为客户提供“安心”运行保证的设计理念。",
        title1: "超高层垂直物流输送系统系统",
        title2: "手动叉车装卸垂直物流输送系统（单出口型）",
        title3: "手动叉车装卸垂直物流输送系统机（双出口型）",
        title4: "滚筒型垂直物流输送系统（双出口型）",
        title5: "垂直物流输送系统（单出口型）",
        video: "观看视频"
      },
      [enUS]: {
        title: "Standard Pallet Type",
        subtitle: "The equipment adopts independent frequency conversion control technology, which makes the acceleration and deceleration in transportation very smooth, and there is no need to worry about the scattering and dumping of goods.At the same time, the product frame is designed as a vertical tower with strong seismic capacity, can be installed in new and old buildings.",
        feature1: "The machine is recognized as an unmanned elevator of mechanical equipment , it can be inspected at four times per year autonomously, thus reducing the operating cost.",
        feature2: "Adopt independent frequency conversion control, accelerate and decelerate very smoothly,no need worry about goods scattering, dumping problem.",
        feature3: "The product adopts vertical tower frame, which has strong seismic capability, the equipment can be set up in both old and new buildings.",
        feature4: "Perfect safety device is adopted which showing the design concept of providing customers with peace of mind operation guarantee.",
        title1: "LIFT Automatic Logistics  System（Super High type）",
        title2: "Hand forklift loading and unloading LIFT Automatic Logistics  System（Single outlet）",
        title3: "Hand forklift loading and unloading LIFT Automatic Logistics  System（ Double outlet）",
        title4: "Rolling Conveyor Loading and unloading LIFT Automated Logisitics system（Double outlet）",
        title5: "Stacking Forklift Loading and unloading  LIFT Automatic Logistics System（Single outlet）",
        video: "Video"
      }
    }
  }
});
