import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, withCtx as _withCtx, resolveDynamicComponent as _resolveDynamicComponent, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { id: "hoister" }
const _hoisted_2 = { class: "field has-addons has-addons-centered" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_TheHero = _resolveComponent("TheHero")
  const _component_TheAdvantages = _resolveComponent("TheAdvantages")
  const _component_VSection = _resolveComponent("VSection")
  const _component_TheTransplotRoutes = _resolveComponent("TheTransplotRoutes")
  const _component_TheCases = _resolveComponent("TheCases")

  return (_openBlock(), _createBlock("section", _hoisted_1, [
    _createVNode(_component_TheHero),
    _createVNode(_component_TheAdvantages),
    _createVNode(_component_VSection, {
      title: _ctx.$t('type'),
      style: {"padding-bottom":"0"}
    }, {
      default: _withCtx(() => [
        _createVNode("div", _hoisted_2, [
          (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.tabs, (tab, key) => {
            return (_openBlock(), _createBlock("p", {
              key: key,
              class: "control"
            }, [
              _createVNode("a", {
                onClick: $event => (_ctx.currentTab = key),
                class: ["button is-medium", key === _ctx.currentTab && 'is-focused'],
                style: {"height":"64px"}
              }, [
                _createVNode("span", null, _toDisplayString(_ctx.$t(tab.text)), 1)
              ], 10, ["onClick"])
            ]))
          }), 128 /* KEYED_FRAGMENT */))
        ])
      ]),
      _: 1
    }, 8, ["title"]),
    (_ctx.tabs[_ctx.currentTab].component)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.tabs[_ctx.currentTab].component), { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode(_component_TheTransplotRoutes),
    _createVNode(_component_TheCases, { images: [
        'hoisters/cases/10.jpeg',
        'hoisters/cases/9.jpeg',
        'hoisters/3.png',
        'hoisters/cases/4.jpeg',
        'hoisters/cases/6.jpeg',
        'hoisters/cases/7.jpeg',
      ] })
  ]))
}