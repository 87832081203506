
import { defineComponent } from "vue";
import { enUS, zhCN } from "@/plugins/i18n";
import VImage from "@/components/Image.vue";
import VSection from "@/components/VSection/index.vue";

export default defineComponent({
  name: "Cases",

  components: {
    VImage,
    VSection,
  },

  props: {
    images: {}
  },

  i18n: {
    messages: {
      [zhCN]: {
        title: "使用案例"
      },
      [enUS]: {
        title: "Usage Cases"
      }
    }
  }
});
